export enum LUNAR_CODE {
  SOLAR = 'SOLAR',
  LUNAR = 'LUNAR',
}
export enum STATUS_YN {
  YES = 'Y',
  NO = 'N',
}
export enum GENDER_CODE {
  MALE = 'M',
  FEMAIL = 'F',
}
export enum POST_RECPTN_DIV {
  HOME = 'HOME',
  COMPANY = 'COMPANY',
  RESVE = 'RESVE',
  N = 'N',
}
export enum GROUP {
  YEAR = 'YEAR',
  NOR = 'NOR',
}
export enum DORMANT_ACCOUNT_CONVERSION_CODE {
  UNTIL_OUT = 'OUT',
  YEAR = '1',
}

export enum RESERVATION_STATUS {
  END = 'END',
  UNOPENED = 'UNOPENED',
  ING = 'ING',
  PAST = 'PAST',
  WAIT = 'WAIT',
  CLOSE = 'CLOSE',
  TODAY = 'TODAY',
  ZERO = 'ZERO',
}

export enum RESERVATION_PACKAGE_STATUS {
  END = 'END',
  UNOPENED = 'UNOPENED',
  ING = 'ING',
  PAST = 'PAST',
}

export enum NO_DATA_SETTINGS_TYPE {
  DEFAULT = 'DEFAULT',
  RESVE = 'RESVE',
}

export enum PAYMENT_METHOD {
  CARD = 'CARD',
  BANK = 'BANK',
  VBANK = 'VBANK',
  CELLPHONE = 'CELLPHONE',
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  LPAY = 'LPAY',
  PINPAY = 'PINPAY',
  SAMSUNGPAY = 'SAMSUNGPAY',
  TOSS = 'TOSS',
  LINEPAY = 'LINEPAY',
  TMONEYPAY = 'TMONEYPAY',
}

export enum VERIFY_CODE {
  JOIN = 'JOIN', // 회원가입
  PASS = 'PASS', // 비밀번호 변경
  DEFAULT = 'DEFAULT', // 그 외(회원정보 수정 등)
  MEMBER = 'MEMBER', // 정회원가입일때
}

export enum COURSE_CLASS {
  MEMBER = 'R',
  PUBLIC = 'P',
}
