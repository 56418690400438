import { WeatherApiEndpoint } from '@core/api-endpoint';
import { FetchWeatherResponse, FetchWeatherWeekResponse } from '@core/dto';
import { HttpInterface } from '@core/http';

/**
 * 핵심비즈니스로직과 상관없어서 인터페이스 만들지 않음
 */
export class WeatherAdapter {
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }

  async fetchWeather(date: string): Promise<FetchWeatherResponse | {}> {
    try {
      const url = this.http.replacedUrl(WeatherApiEndpoint.fetchWeather, {
        date,
      });
      const response = await this.http.get<FetchWeatherResponse>(url);
      return response.data;
    } catch (error) {
      return {};
    }
  }

  async fetchWeatherWeek(date: string): Promise<FetchWeatherWeekResponse | {}> {
    try {
      const url = this.http.replacedUrl(WeatherApiEndpoint.fetchWeatherWeek, {
        date,
      });
      const response = await this.http.get<FetchWeatherWeekResponse>(url);
      return response.data;
    } catch (error) {
      return {};
    }
  }
}
