import { ROUTE_NAME } from '@constants';
import { AuthResponseAdapter } from '@core/adapters';
import { AuthApiEndpoint, UserApiEndpoint } from '@core/api-endpoint';
import {
  CheckIdDuplicatePayload,
  FindIdPayload,
  FindIdResponse,
  MemberShipChangePayload,
  MembershipChangeResponse,
  OAuthSnsPayload,
  OAuthSnsResponse,
  RealNameCertificateDecDataPayload,
  RealNameCertificateDecResponse,
  RealNameCertificateEncDataPayload,
  RealNameCertificateEncDataResponse,
  SendVerificationCodePayload,
  SignInAdminPayload,
  SignInAdminResponse,
  SignInPayload,
  SignInResponse,
  UpdatePasswordPayload,
  VerifyVerificationCodeForMemberResponse,
  VerifyVerificationCodePayload,
} from '@core/dto';
import { HttpInterface } from '@core/http';
import { AuthModel } from '@core/models';
import { AuthPort } from '@core/ports';
import { useCommonStore, useUserStore } from '@stores';
import axios from 'axios';
import { useRouter } from 'vue-router';

type CommonStore = ReturnType<typeof useCommonStore>;
type UserStore = ReturnType<typeof useUserStore>;
export class AuthAdapter
  implements
    AuthPort<
      any,
      | SignInPayload
      | FindIdPayload
      | CheckIdDuplicatePayload
      | UpdatePasswordPayload
      | SignInAdminPayload
      | SendVerificationCodePayload
      | VerifyVerificationCodePayload
      | RealNameCertificateEncDataPayload
      | RealNameCertificateDecDataPayload
      | OAuthSnsPayload
      | MemberShipChangePayload
    >
{
  protected router = useRouter();
  http: HttpInterface;
  commonStore: CommonStore;
  userStore: UserStore;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
    this.commonStore = useCommonStore();
    this.userStore = useUserStore();
  }
  async signIn(payload: SignInPayload): Promise<AuthModel> {
    try {
      const response = await this.http.post<SignInResponse>(
        AuthApiEndpoint.signIn,
        payload
      );
      return await this.handleSignInResponse(response.data);
    } catch (error) {
      return await this.handleSignInError(error);
    }
  }

  protected async handleSignInResponse(
    response: SignInResponse
  ): Promise<AuthModel> {
    const authInfo = AuthResponseAdapter.signInAdapt(response);
    const authModel = AuthModel.createFromResponse(authInfo);
    const accessToken = authModel.getAccessToken();
    const membershipId = authModel.getMembershipId();
    this.commonStore.setAccessToken(accessToken, membershipId);
    this.userStore.setUser(accessToken);
    return authModel;
  }

  protected async handleSignInError(error: unknown): Promise<never> {
    if (axios.isAxiosError(error) && error.response) {
      const errorCode = error.response.data.status;
      if (errorCode == 'ID_PASSWORD_MISMATCH_FORWARD_TO_PW_FIND_VIEW') {
        await this.router.push({ name: ROUTE_NAME.FindPassword });
      }
    }
    throw error;
  }

  async signOut(): Promise<boolean> {
    const response = await this.http.delete<boolean>(AuthApiEndpoint.signOut);
    this.commonStore.clearAccessToken();
    this.userStore.resetUser();
    return response.data;
  }
  async sendVerificationCode(
    payload: SendVerificationCodePayload
  ): Promise<number | null> {
    const response = await this.http.post<number | null>(
      AuthApiEndpoint.sendVerificationCode,
      payload
    );
    return response.data;
  }
  async verifyVerificationCode(
    payload: VerifyVerificationCodePayload
  ): Promise<string | null> {
    const response = await this.http.post<string | null>(
      AuthApiEndpoint.verifyVerificationCode,
      payload
    );
    return response.data;
  }
  async verifyVerificationCodeForMember(
    payload: VerifyVerificationCodePayload
  ): Promise<VerifyVerificationCodeForMemberResponse> {
    const response =
      await this.http.post<VerifyVerificationCodeForMemberResponse>(
        AuthApiEndpoint.verifyVerificationCodeForMember,
        payload
      );
    return response.data;
  }
  async updatePassword(
    payload: UpdatePasswordPayload
  ): Promise<boolean | null> {
    const response = await this.http.put<boolean | null>(
      AuthApiEndpoint.updatePassword,
      payload
    );
    return response.data;
  }
  async updatePasswordNow(payload: UpdatePasswordPayload): Promise<void> {
    await this.http.put<void>(AuthApiEndpoint.updatePasswordNow, payload);
  }
  async updatePasswordNext(): Promise<void> {
    await this.http.put<void>(AuthApiEndpoint.updatePasswordNext);
  }
  async findId(payload: FindIdPayload): Promise<FindIdResponse> {
    const response = await this.http.post<FindIdResponse>(
      AuthApiEndpoint.findId,
      payload
    ); // TODO: 이거 왜 post ??
    return AuthResponseAdapter.findIdAdapt(response.data);
  }
  async checkIdDuplicate(
    payload: CheckIdDuplicatePayload
  ): Promise<boolean | null> {
    const response = await this.http.get<boolean | null>(
      UserApiEndpoint.checkUserIdDuplicate,
      payload
    );
    return response.data;
  }
  async signInAdmin(payload: SignInAdminPayload): Promise<SignInAdminResponse> {
    const response = await this.http.post<SignInAdminResponse>(
      AuthApiEndpoint.signInAdmin,
      payload
    );
    this.userStore.setUser(response.data.accessToken);
    this.commonStore.setAccessToken(response.data.accessToken);
    return response.data;
  }
  async fetchRealNameCertificateEncData(
    payload: RealNameCertificateEncDataPayload
  ): Promise<RealNameCertificateEncDataResponse> {
    const response = await this.http.post<RealNameCertificateEncDataResponse>(
      AuthApiEndpoint.niceCertificationEnc,
      payload
    );
    return response.data;
  }
  async fetchRealNameCertificateDecData(
    payload: RealNameCertificateDecDataPayload
  ): Promise<RealNameCertificateDecResponse> {
    const response = await this.http.post<RealNameCertificateDecResponse>(
      AuthApiEndpoint.niceCertificationDec,
      payload
    );
    return response.data;
  }
  async fetchOAuthToken(payload: OAuthSnsPayload): Promise<OAuthSnsResponse> {
    const { snsKind } = payload;
    const url = this.http.replacedUrl(AuthApiEndpoint.oAuthSns, { snsKind });
    const response = await this.http.post<OAuthSnsResponse>(url, payload);
    return response.data;
  }
  async membershipChange(payload: MemberShipChangePayload): Promise<void> {
    const {
      data: { accessToken },
    } = await this.http.post<MembershipChangeResponse>(
      AuthApiEndpoint.memberShipChange,
      payload
    );
    this.commonStore.setAccessToken(accessToken, payload.membershipId);
    this.userStore.setUser(accessToken);
  }
}
