<template>
  <div>
    <div id="footer">
      <ul class="link-box">
        <li>
          <a
            href="http://thecommunitya.co.kr/index.php"
            target="_blank"
            title="커뮤니티A홈"
          >
            <img
              src="/src/infrastructure/assets/img/mobile/footer_btn_04.jpg"
              alt="커뮤니티A홈"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.citycollege.ac/about-4"
            target="_blank"
            title="씨티평생교육원"
          >
            <img
              src="/src/infrastructure/assets/img/mobile/footer_btn_01.jpg"
              alt="씨티평생교육원"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.goyang-plantation.com/"
            target="_blank"
            title="고양플랜테이션"
          >
            <img
              src="/src/infrastructure/assets/img/mobile/footer_btn_02.jpg"
              alt="고양플랜테이션"
            />
          </a>
        </li>
        <li>
          <a
            href="https://oasismedihome.kr/oasismed/?mode=main"
            target="_blank"
            title="오아시스메디홈"
          >
            <img
              src="/src/infrastructure/assets/img/mobile/footer_btn_03.jpg"
              alt="오아시스메디홈"
            />
          </a>
        </li>
      </ul>
      <div class="privacy-box">
        <ul>
          <li @click="handleAgreePopup('privacyPolicyAgree')">
            개인정보취급방침
          </li>
          <li @click="handleAgreePopup('serviceUsageAgree')">
            골프장 이용약관
          </li>
        </ul>
      </div>
      <div class="info-box">
        <ul>
          <li>
            <span>주소</span> 경기도 고양시 덕양구 벽제동 465 (주)올림픽
            컨트리클럽
          </li>
          <li><span>대표</span> 이관식</li>
          <li><span>사업자등록번호</span> 128-81-06238</li>
          <li><span>전화번호</span> 031-962-0101</li>
          <li><span>통신판매업신고</span> 2011-경기고양-1346</li>
        </ul>
        <p>Copyright Olymplic Country Club ALL RIGHT RESERVED.</p>
      </div>
    </div>
    <MModal ref="modal">
      <template #modal-body>
        <ServiceUsageAgree v-if="agreeType === 'serviceUsageAgree'" />
        <PrivacyPolicyAgree v-if="agreeType === 'privacyPolicyAgree'" />
        <MarketingAgree v-if="agreeType === 'marketingAgree'" />
      </template>
    </MModal>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { MModal } from '@molecules';
import PrivacyPolicyAgree from '@components/common/PrivacyPolicyAgree.vue';
import ServiceUsageAgree from '@components/common/ServiceUsageAgree.vue';
import MarketingAgree from '@components/common/MarketingAgree.vue';
const modal = ref();
const agreeType = ref('');

const handleAgreePopup = (type: string) => {
  agreeType.value = type;
  modal.value.open();
};
</script>

<style scoped lang="scss">
#footer {
  width: 100%;
  height: 288px;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
}

.link-box {
  display: flex;
  width: 100%;
  height: 60px;
  li {
    width: 25%;
  }
}

.link-box img {
  height: 100%;
  object-fit: cover;
}

.privacy-box {
  width: 100%;
  height: 48px;
  background-color: #006b57;
}

.privacy-box ul {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.privacy-box ul li {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  padding: 14px 32px 0 0;
}

.privacy-box ul li:first-child {
  font-weight: 600;
}

.info-box {
  width: 100%;
  height: 190px;
  background-color: #222222;
}

.info-box ul {
  width: 90%;
  margin: 0 auto;
  padding-top: 16px;
}

.info-box ul li {
  font-size: 13px;
  font-weight: 300;
  padding-bottom: 6px;
  color: #cccccc;
}

.info-box ul li span {
  font-weight: 500;
}

.info-box p {
  width: 90%;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin: 0 auto;
  padding-top: 16px;
}
</style>
