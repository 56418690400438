export const CommonApiEndpoint = {
  fetchListByGroupCode: '/system/common-group-codes/:groupCode/common-codes',
  fetchDetailByComCode:
    '/system/common-group-codes/:groupCode/common-codes/:comCode',
  fetchAttrListByGroupCode:
    '/system/common-group-codes/:groupCode/common-attrbs',
  // fetchPenaltyConfigList: '/system/configurations/penalty', // 페널티 구성 조회??
  fetchGroupRegistSetting: '/system/configurations/group-reception', // 단체접수환경설정 조회?
  fetchDetailRegion: '/sis', // 시군구 조회
  requestCaptcha: '/captcha',
  fetchMainBoards: '/main/boards', // 메인화면에 노출될 게시글 리스트
  fetchMainBanners: '/banners', // 메인화면에 노출될 배너 리스트
  commonTotalInfo: '/system/configurations', // 기존 시스템 정보 조회
  fetchSystemConfig: '/system/config', // 신규 시스템 정보 조회
};
